import { Component } from 'react';

export default class About extends Component {
    render() {
        return (
            <div className="about">
                <h1>Personligtbrev</h1>

                <p>
                    Jag är en person med ett stort intresse för teknik och datorer. Jag har alltid varit nyfiken på hur saker fungerar och hur de kan förbättras. Jag har ett särskilt intresse för Linux och Java-utveckling. Under många år har jag arbetat med Linux och har omfattande erfarenhet av systemadministration och felsökning. Jag har också arbetat med webbutveckling och har erfarenhet av HTML, CSS och JavaScript. Jag söker alltid nya utmaningar och möjligheter att lära mig nya saker.

                    På min fritid experimenterar jag ofta med att sätta upp egna virtuella maskiner eller sätta upp mina egna webbapplikationer. Jag är även intresserad av molntjänster och har erfarenhet av att arbeta med S3 Objekt lagring.
                </p>
            </div>
        );
    }
}

class Config {
    constructor() {
        this.config = null
    }

    setConfig(config) {
        this.config = config;
    }

    getConfig() {
        return this.config;
    }

    gethostUrl() {
        return this.config.hostUrl;
    }

    getbaseUrl() {
        return this.config.baseUrl;
    }

    app = {
        get: (url, callback, error) => {
            this.app.fetch(url, null, 'GET', callback, error);
        },
        post: (url, body, callback, error) => {
            this.app.fetch(url, body, 'POST', callback, error);
        },
        delete: (url, body, callback, error) => {
            this.app.fetch(url, body, 'DELETE', callback, error);
        },
        put: (url, body, callback, error) => {
            this.app.fetch(url, body, 'PUT', callback, error);
        },
        patch: (url, body, callback, error) => {
            this.app.fetch(url, body, 'PATCH', callback, error);
        },
        fetch: (url, body, method, callback, errorCallback) => {
            const urlEncodedBody = new URLSearchParams();
            for (const key in body) {
                urlEncodedBody.append(key, body[key]);
            }

            let options = {
                method: method,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: urlEncodedBody.toString(),
            };

            if (method === 'GET' || method === 'HEAD') {
                delete options.body;
            }

            fetch(url, options)
                .then(data => {
                    let failed = !data.ok;
                    let promise;
                    let CTHeader = data.headers.get('Content-Type');

                    if (CTHeader !== undefined && CTHeader.includes('application/json')) {
                        promise = data.json();
                    } else {
                        promise = data.text();
                    }

                    promise.then((data) => {
                        if (!failed && typeof data !== 'object') {
                            throw new Error('Invalid app config');
                        }

                        if (failed) {
                            errorCallback(data.message);
                        } else {
                            callback(data);
                            errorCallback('');
                        }
                    })
                        .catch((error) => {
                            errorCallback(error.message);
                            console.error('Error:', error);
                        });
                })
                .catch((error) => {
                    errorCallback(error.message);
                    console.error('Error:', error);
                });
        }
    }
}

export default Config;
import React, { Component } from 'react';

import '../assets/css/Skills.css';
import '../assets/css/About.css';

export default class Skills extends Component {
    render() {
        const jobs = [
            {
                name: 'Abion AB (Ports Group AB), Göteborg',
                title: 'Linuxtekniker',
                date: '2023 - Nuvarande',
                description: 'Linuxtekniker, med uppgiter som systemadministration, framtagning av automatiserade processer och drift av DNS-tjänster.',
            },
            {
                name: '1999it, Kungsbacka',
                title: 'IT-tekniker',
                date: '2021 - 2023',
                description: 'IT-tekniker med fokus på privatpersoner och mindre företag. Arbetade med felsökning, installation och underhåll av datorer och nätverk.',
            },
            {
                name: 'Repairstore',
                title: 'Mobilreparatör',
                date: '2020',
                description: 'Mobilreparatör, reparerade trasiga mobiltelefoner och surfplattor.',
            },
        ];

        const education = [
            {
                name: 'Lindholmens Tekniska Gymnasium, Göteborg',
                title: 'El och Energi programmet',
                date: '2018 - 2021',
                description: 'Gick el och energiprogrammet där jag även valde datateknik som inriktning.',
                project: 'Linux DNS Server',
            },
        ];

        const skills = [
            'Linux',
            'Java',
            'NodeJS',
            'React',
            'GoLang',
            'Bash',
            'Databases',
            'DNS',
            'CSS',
            'HTML',
            'JavaScript',
            'Git',
            'Docker',
            'Kubernetes',
            'Microsoft 365 for Business',
        ];

        return (
            <div>
                <div className="about">
                    <h1>Arbetslivserfarenhet</h1>

                    {jobs.map((job, index) => (
                        <section className="job">
                            <h2>{job.name}</h2>
                            <h3>{job.title} | {job.date}</h3>
                            <div className="job-details">
                                <p>
                                    {job.description}
                                </p>
                            </div>
                        </section>
                    ))}


                    <h1>Utbildning</h1>

                    {education.map((edu, index) => (
                        <section className="about-item">
                            <h2>{edu.name}</h2>
                            <h3>{edu.title} | {edu.date}</h3>
                            <div className="about-details">
                                <p>
                                    {edu.description}
                                </p>
                                <p>
                                    Gymnasiearbete: {edu.project}
                                </p>
                            </div>
                        </section>
                    ))}
                </div>
                <div className="skills">
                    <h1>Kompetenser</h1>
                    <ul className="skills-list">
                        {skills.map((skill, index) => (
                            <li key={index} className="skill-item">
                                {skill}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    }
}
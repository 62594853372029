// Nav.js
import React, { Component } from "react";
import NavLinks from "./NavLinks";

import "../assets/css/Nav.css";

export default class Nav extends Component {
    state = {
        isMobileMenuOpen: false,
    };

    toggleMobileMenu = () => {
        this.setState((prevState) => ({
            isMobileMenuOpen: !prevState.isMobileMenuOpen,
        }));
    };

    render() {
        return (
            <nav className="navigation">
                <button className="hamburger" onClick={this.toggleMobileMenu}>
                    &#9776;
                </button>
                <div
                    className={`nav-links ${this.state.isMobileMenuOpen ? "open" : ""}`}
                >
                    <NavLinks toggleMenu={this.toggleMobileMenu} />
                </div>
            </nav>
        );
    }
}

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import logo from './assets/images/logo.svg';
import './assets/css/App.css';

import Loader from './Loader';
import Nav from './components/Nav';
import Home from './components/Home';
import About from './components/About';
import Skills from './components/Skills';
import Projects from './components/Projects';
import Contact from './components/Contact';

export default function App(props) {
  const [loaded, setLoaded] = useState(false);
  const [globalLoaded, setGlobalLoaded] = useState(false);

  useEffect(() => {
    const loaderCache = localStorage.getItem('loaderCache');
    if (!loaderCache) {
      setGlobalLoaded(false);
      const loadTimeout = setTimeout(() => {
        setGlobalLoaded(true);
        localStorage.setItem('loaderCache', true);
      }, 1000);
      return () => clearTimeout(loadTimeout);
    } else {
      setGlobalLoaded(true);
    }
    setLoaded(true);
  }, []);

  return (
    <Router>
      <Loader loaded={globalLoaded}>
        <div className="App">
          <header className="App-header">
            <Link to="/">
              <img src={logo} className="App-logo" alt="logo" />
            </Link>
            <span className="App-title">Amadeus' Hemsida</span>
            <span className="App-subtitle">Linuxtekniker och Utvecklare</span>
            <Nav />
          </header>

          <Loader loaded={loaded}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/skills" element={<Skills />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </Loader>
        </div>
      </Loader>
    </Router>
  );
}

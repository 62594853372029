import React, { Component } from 'react';
import './assets/css/Loader.css';
import { PulseThree } from 'react-svg-spinners';

class Loader extends Component {
    render() {
        if (!this.props.loaded) {
            return (
                <div className="loader">
                    {this.props.error && <p className="error">{this.props.error}</p>}
                    <PulseThree color="#3498db" width={50} height={50} />
                </div>
            );
        } else {
            return this.props.children;
        }
    }
}

export default Loader;

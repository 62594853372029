import React, { Component } from 'react';

export default class Projects extends Component {
    render() {
        const projects = [
            {
                name: 'Steam Hourboosting Service',
                description: 'En Steam-timme-boosting-tjänst byggd med NodeJS som använder PM2 för att snurra upp underprocesser.',
                technologies: ['NodeJS', 'PM2'],
                link: 'https://owl.tf',
            },
            {
                name: 'Currency Manager',
                description: 'Byggd med en React frontend och en Java Jakarta EE backend. Används för att ladda ner valutadata varje X timmar och konvertera mellan dem.',
                technologies: ['React', 'Java (Jakarta EE)'],
                link: 'https://crm.rc-wreding.xyz',
            },
            {
                name: 'Counter Strike Community',
                description: 'Ett community för Counter Strike: Global Offensive med forum och spelservrar.',
                technologies: ['SourceMod', 'MariaDB', 'PHP'],
                eol: true,
            },
            {
                name: 'File Share',
                description: 'En fildelningsplattform byggd med NodeJS och Mega.nz API.',
                technologies: ['NodeJS', 'Mega.nz API'],
                eol: true,
            }
        ];

        return (
            <div className="projects">
                <h1>Projekt</h1>
                <div className="projects-list">
                    {projects.map((project, index) => (
                        <div key={index} className="project-item">
                            <h2>{project.name}</h2>
                            <p>{project.description}</p>
                            <p>
                                <strong>Teknologier:</strong> {project.technologies.join(', ')}
                            </p>
                            {project.link && (
                                <a href={project.link} target="_blank" rel="noopener noreferrer">
                                    Besök Projekt
                                </a>
                            )}
                            {project.eol && (
                                <p className="eol">Projektet är inte längre aktivt.</p>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}
// Create reactjs About component
//

import React from 'react';

function About() {
    return (
        <div>
            <h1>Välkommen till min hemsida</h1>
            <p>
                Här hittar du information om mig och mina färdigheter.
            </p>
        </div>
    );
}

export default About;
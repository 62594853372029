import React, { Component } from 'react';
import '../assets/css/Contact.css';
import Loader from '../Loader';

export default class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageLoaded: false,
            loaded: false,
        };
    }

    render() {
        return (
            <div className="contact">
                <h1>Kontakt</h1>

                <div className="contact-info">
                    {!this.state.imageLoaded && (
                        <div className="image-placeholder">
                            <Loader loaded={this.state.imageLoaded} />
                        </div>
                    )}
                    <img
                        src="amadeus.wreding.webp"
                        alt="Amadeus Wreding"
                        onLoad={() => { this.setState({ imageLoaded: true, loaded: true }); }}
                        style={{ display: this.state.imageLoaded ? 'block' : 'none' }}
                    />
                    <p>
                        <strong>Telefon:</strong> 0706-35 75 13<br />
                        <strong>E-post:</strong> <a href="mailto:amadeus.jobb@wreding.com">amadeus.jobb@wreding.com</a><br />
                        <strong>Adress:</strong> Stormgatan 46, 434 34 Kungsbacka<br />
                    </p>
                </div>
            </div>
        );
    }
}

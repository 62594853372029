// NavLinks.js
import { Link, useLocation } from "react-router-dom";

const NavLinks = ({ toggleMenu }) => {
    const location = useLocation();
    return (
        <div className="App-nav">
            <Link
                to="/"
                className={location.pathname === "/" ? "active" : ""}
                onClick={toggleMenu}
            >
                Hem
            </Link>
            <Link
                to="/about"
                className={location.pathname === "/about" ? "active" : ""}
                onClick={toggleMenu}
            >
                Personligtbrev
            </Link>
            <Link
                to="/skills"
                className={location.pathname === "/skills" ? "active" : ""}
                onClick={toggleMenu}
            >
                CV
            </Link>
            <Link
                to="/projects"
                className={location.pathname === "/projects" ? "active" : ""}
                onClick={toggleMenu}
            >
                Projekt
            </Link>
            <Link
                to="/contact"
                className={location.pathname === "/contact" ? "active" : ""}
                onClick={toggleMenu}
            >
                Kontakt
            </Link>
        </div>
    );
};

export default NavLinks;
